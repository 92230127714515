import { Accordion, Affix, Card, Drawer, Flex, Text } from '@mantine/core';
import classes from './MainSearchFilterDrawer.module.scss';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import ScheduleFilterContent from 'components/MainFilterContent/ScheduleFilterContent/ScheduleFilterContent';
import LocationFilterContent from 'components/MainFilterContent/LocationFilterContent/LocationFilterContent';
import AgeFilterContent from 'components/MainFilterContent/AgeFilterContent/AgeFilterContent';
import ActivityFilterContent from 'components/MainFilterContent/ActivityFilterContent/ActivityFilterContent';
import { getAgeRangeLabel } from 'utils/filters/filters';
import { useRouter } from 'next/router';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { Actions, trackAction } from 'utils/amplitude';
import { useState } from 'react';
import { PebbleButtonsEnum, SearchBarDropdownEnum } from 'enums';
import ResetSearchFilterButton from 'components/ui/ResetSearchFilterButtons/ResetSearchFilterButton';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import { PebbleButtonSet } from 'components/ui';

const MainSearchFilterDrawer = () => {
  const router = useRouter();
  const {
    showFiltersDrawer,
    toggle,
    locationString,
    selectedDatesLabel,
    selectedDaysLabels,
    redirectUrl,
    activitySearchTerms,
    resetAllFilters,
    searchFilterState: { selectedAgeStart, selectedAgeEnd },
  } = useSavedSearchValues();

  const ageRangeLabel = getAgeRangeLabel(true, selectedAgeStart, selectedAgeEnd);
  const [accordionValue, setAccordionValue] = useState<string | null>(
    SearchBarDropdownEnum.location,
  );

  const URLpathname = getURLpathnameParam(router.pathname);

  return (
    <Drawer
      opened={showFiltersDrawer}
      onClose={toggle}
      position="bottom"
      size="100%"
      lockScroll
      classNames={{
        content: classes.drawerContent,
        header: classes.drawerHeader,
        close: classes.drawerCloseButton,
        body: classes.drawerBody,
      }}
    >
      <Accordion
        defaultValue={SearchBarDropdownEnum.location}
        value={accordionValue}
        onChange={setAccordionValue}
        classNames={{
          chevron: classes.accordionChevron,
          item: classes.accordionItem,
        }}
      >
        <Accordion.Item value={SearchBarDropdownEnum.location as string}>
          <Accordion.Control
            onClick={() =>
              trackAction(Actions.SEARCH_LOCATION_SELECT, {
                URLpathname,
              })
            }
          >
            <Flex justify="space-between">
              <Text className={classes.cardLeftSection}>Location </Text>

              <Text className={classes.cardRightSection}>{locationString || ' '}</Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <LocationFilterContent />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value={SearchBarDropdownEnum.age as string}>
          <Accordion.Control
            disabled={!locationString}
            onClick={() =>
              trackAction(Actions.SEARCH_AGE_SELECT, {
                URLpathname,
              })
            }
          >
            <Flex justify="space-between" align="center">
              <Text className={classes.cardLeftSection}>Ages</Text>
              {accordionValue === SearchBarDropdownEnum.age ? (
                <ResetSearchFilterButton
                  filterType={SearchBarDropdownEnum.age}
                  setAccordionValue={setAccordionValue}
                />
              ) : (
                <Text className={classes.cardRightSection}>{ageRangeLabel || 'All ages'}</Text>
              )}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <AgeFilterContent />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value={SearchBarDropdownEnum.when as string}>
          <Accordion.Control
            disabled={!locationString}
            onClick={() =>
              trackAction(Actions.SEARCH_WHEN_SELECT, {
                URLpathname,
              })
            }
          >
            <Flex justify="space-between" align="center">
              <Text className={classes.cardLeftSection}>When</Text>
              {accordionValue === SearchBarDropdownEnum.when ? (
                <ResetSearchFilterButton
                  filterType={SearchBarDropdownEnum.when}
                  setAccordionValue={setAccordionValue}
                />
              ) : (
                <Text className={classes.cardRightSection}>
                  {selectedDatesLabel()} {selectedDaysLabels()}
                </Text>
              )}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <ScheduleFilterContent />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value={SearchBarDropdownEnum.activity as string}>
          <Accordion.Control
            disabled={!locationString}
            onClick={() =>
              trackAction(Actions.SEARCH_ACTIVITY_SELECT, {
                URLpathname,
              })
            }
          >
            <Flex justify="space-between" align="center">
              <Text className={classes.cardLeftSection}>Activity</Text>

              {accordionValue === SearchBarDropdownEnum.activity ? (
                <ResetSearchFilterButton
                  filterType={SearchBarDropdownEnum.activity}
                  setAccordionValue={setAccordionValue}
                />
              ) : (
                <Text className={classes.cardRightSection}>
                  {!activitySearchTerms
                    ? 'All activities'
                    : capitalizeFirstLetter(activitySearchTerms[0])}
                  {activitySearchTerms && activitySearchTerms.length > 1 && '...'}
                </Text>
              )}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <ActivityFilterContent />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      {showFiltersDrawer && (
        <Affix position={{ bottom: 0, left: 0, right: 0 }} zIndex={201}>
          <Card radius={0} className={classes.footerButtonsContainer}>
            <Flex justify="start">
              <PebbleButtonSet
                btnVariant={PebbleButtonsEnum.TERTIARY}
                size="md"
                onClick={() => {
                  resetAllFilters();
                  trackAction(Actions.SEARCH_CLEAR_ALL);
                }}
                disabled={!locationString}
                mr={'3rem'}
                pl={0}
              >
                Clear all
              </PebbleButtonSet>
              <PebbleButtonSet
                btnVariant={PebbleButtonsEnum.PRIMARY}
                size="md"
                onClick={() => {
                  router.push(redirectUrl);
                  toggle();
                  trackAction(Actions.SEARCH_SHOW_ACTIVITIES, {
                    URLpathname,
                  });
                }}
                disabled={!locationString}
              >
                Show activities
              </PebbleButtonSet>
            </Flex>
          </Card>
        </Affix>
      )}
    </Drawer>
  );
};

export default MainSearchFilterDrawer;
